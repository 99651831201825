/*
	Dopetrope by HTML5 UP
	html5up.net | @n33co
	Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/

/* Basic */

	form input[type=text],
	form input[type=password],
	form select,
	form textarea {
		position: relative;
		-ms-behavior: url("assets/js/ie/PIE.htc");
	}

	input[type="button"],
	input[type="submit"],
	input[type="reset"],
	button,
	.button,
	ul.social li a,
	.icon.featured,
	#copyright .links,
	#nav > ul > li > a {
		position: relative;
		-ms-behavior: url("assets/js/ie/PIE.htc");
	}

/* Nav */

	.dropotron {
		position: relative;
		-ms-behavior: url("assets/js/ie/PIE.htc");
	}

		.dropotron.level-0 {
			box-shadow: none !important;
		}

/* Banner */

	#banner {
		background-position: auto;
		background-size: contain;
		-ms-behavior: url("assets/js/ie/backgroundsize.min.htc");
	}
